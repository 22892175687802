import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import {
    container,
    mainRatio,
    mainImageBox,
    logoImageBox,
    list,
    hoverLayer,
} from './brand-social-card.module.scss';
import { config } from '../../config';
import { IBrandSocial } from '../../models/brand-social.model';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';

import RatioImage from '../atoms/ratio-image';
import SocialLink from '../atoms/social-link';

const { translationKeys, relations } = config;
const DEFAULT_BACKGROUND_COLOR = '#FFF';

interface IBrandSocialCardProps {
    className?: string;
    brandSocial: IBrandSocial;
}

const BrandSocialCard: React.FC<IBrandSocialCardProps> = ({ className = '', brandSocial }) => {
    const { links, color, media } = brandSocial;
    const { name } = useTranslation(brandSocial, translationKeys.brandSocial);

    const mainMediaItem = getMediaWithRelation(media, relations.mainImage);
    const mainImage = mainMediaItem?.remoteImage && getImage(mainMediaItem.remoteImage);
    const logoMediaItem = getMediaWithRelation(media, relations.secondaryImage, false);
    const logoImage = logoMediaItem?.remoteImage && getImage(logoMediaItem.remoteImage);

    return (
        <div className={`${container} ${className}`}>
            <RatioImage
                className={mainImageBox}
                image={mainImage}
                fallbackUrl={mainMediaItem?.url}
                ratioClass={mainRatio}
                alt={mainMediaItem?.alt}
            />
            <span className={hoverLayer} />
            {logoImage && (
                <div className={logoImageBox}>
                    <GatsbyImage image={logoImage} alt={logoMediaItem?.alt || ''} />
                </div>
            )}
            {links.length > 0 && (
                <ul className={list} style={{ backgroundColor: color || DEFAULT_BACKGROUND_COLOR }}>
                    {links.map((link) => {
                        return (
                            <li key={`social-link-item-${link.linkId}`}>
                                <SocialLink item={link} name={name} />
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default BrandSocialCard;
