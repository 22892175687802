import React, { Fragment, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'react-i18next';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    title,
    content,
    subtitle,
    secondary,
    text,
    button,
} from './about-section.module.scss';

import Title from '../atoms/title';

interface IAboutSectionProps {
    className?: string;
}

const AboutSection: React.FC<IAboutSectionProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const [showMore, setShowMore] = useState(false);

    return (
        <div className={`${container} ${className}`}>
            <Title className={title} size="medium">
                {t('index.about.title')}
            </Title>
            <div className={content}>
                <p className={text}>{t('index.about.intro')}</p>
                <AnimateHeight duration={1000} height={showMore ? 'auto' : 1}>
                    {getSections(t).map((section, index) => {
                        const Tag = section.tag;
                        return (
                            <Fragment key={`about-section-${index}`}>
                                <Tag
                                    className={`${subtitle} ${
                                        section.tag === 'h4' ? secondary : ''
                                    }`}
                                >
                                    {section.subtitle}
                                </Tag>
                                <p className={text}>{section.paragraph}</p>
                            </Fragment>
                        );
                    })}
                </AnimateHeight>
            </div>
            <button className={button} onClick={() => setShowMore(!showMore)}>
                {showMore ? t('button.collapse') : t('button.seeLong')}
            </button>
        </div>
    );
};

interface ISectionAbout {
    tag: React.ElementType;
    subtitle: string;
    paragraph: string;
}

function getSections(t: TFunction<string | string[]>): ISectionAbout[] {
    return [
        {
            tag: 'h3',
            subtitle: t('index.about.section.1.title'),
            paragraph: t('index.about.section.1.content'),
        },
        {
            tag: 'h3',
            subtitle: t('index.about.section.2.title'),
            paragraph: t('index.about.section.2.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.3.title'),
            paragraph: t('index.about.section.3.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.4.title'),
            paragraph: t('index.about.section.4.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.5.title'),
            paragraph: t('index.about.section.5.content'),
        },
        {
            tag: 'h3',
            subtitle: t('index.about.section.6.title'),
            paragraph: t('index.about.section.6.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.7.title'),
            paragraph: t('index.about.section.7.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.8.title'),
            paragraph: t('index.about.section.8.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.9.title'),
            paragraph: t('index.about.section.9.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.10.title'),
            paragraph: t('index.about.section.10.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.11.title'),
            paragraph: t('index.about.section.11.content'),
        },
        {
            tag: 'h4',
            subtitle: t('index.about.section.12.title'),
            paragraph: t('index.about.section.12.content'),
        },
        {
            tag: 'h3',
            subtitle: t('index.about.section.13.title'),
            paragraph: t('index.about.section.13.content'),
        },
    ];
}

export default AboutSection;
