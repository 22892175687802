import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import {
    container,
    player,
    coverLayer,
    imgBox,
    img,
    button,
    iconBox,
    icon,
    text,
} from './video-player.module.scss';
import { config } from '../../config';
import PlayIcon from '../../assets/images/svg/icon-play.svg';
import { IVideo } from '../../models/video.model';
import { IMedia } from '../../models/media.model';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';

const { translationKeys, relations } = config;

interface IVideoPlayerProps {
    autoplay?: boolean;
    playText?: string;
    showCoverOnPause?: boolean;
    video: IVideo;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({
    autoplay = false,
    playText = 'Play',
    showCoverOnPause = false,
    video,
}) => {
    const playerRef = useRef(null);
    const { url } = useTranslation(video, translationKeys.video);
    const [isPlaying, setIsPlaying] = useState(autoplay);
    const [cover, setCover] = useState<IGatsbyImageData | undefined>(getCover(video.media));
    const [showCover, setShowCover] = useState(!!cover);
    const [showCoverTimeout, setShowCoverTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleEnded = (): void => {
        setIsPlaying(false);
        setShowCover(true);
    };

    const handlePause = (): void => {
        if (!showCoverOnPause || !cover) return;
        const newShowCoverTimeout = setTimeout((): void => {
            setIsPlaying(false);
            setShowCover(true);
        }, 300);
        setShowCoverTimeout(newShowCoverTimeout);
    };

    const handlePlay = (): void => {
        if (!showCoverOnPause || !cover || !showCoverTimeout) return;
        setShowCover(false);
        clearTimeout(showCoverTimeout);
    };

    const handleClickOnPlay = (): void => {
        setIsPlaying(true);
        setShowCover(false);
    };

    useEffect(() => {
        setCover(getCover(video.media));
    }, [video.media]);

    return (
        <div className={container}>
            <ReactPlayer
                ref={playerRef}
                className={player}
                url={url}
                playing={isPlaying}
                onEnded={handleEnded}
                onPause={handlePause}
                onPlay={handlePlay}
                width="100%"
                height="100%"
                progressInterval={1000}
                controls
            />
            {cover && showCover && (
                <div className={coverLayer}>
                    <div className={imgBox}>
                        <GatsbyImage alt="" image={cover} className={img} />
                    </div>
                    <button className={button} onClick={handleClickOnPlay}>
                        <div className={iconBox}>
                            <PlayIcon className={icon} />
                        </div>
                        <p className={text}>{playText}</p>
                    </button>
                </div>
            )}
        </div>
    );
};

function getCover(media: IMedia[]): IGatsbyImageData | undefined {
    const mediaItem = getMediaWithRelation(media, relations.mainImage);
    const mediaItemMobile = getMediaWithRelation(media, relations.mainImageMobile);

    const image = mediaItem?.remoteImage && getImage(mediaItem.remoteImage);
    const imageMobile = mediaItemMobile?.remoteImage && getImage(mediaItemMobile.remoteImage);

    return (
        image &&
        imageMobile &&
        withArtDirection(image, [
            {
                image: imageMobile,
                media: `(max-width: 499px)`,
            },
        ])
    );
}

export default VideoPlayer;
