import React from 'react';

import { container, slider, card, slide, heading } from './ring-slider.module.scss';
import { IRing } from '../../models/ring.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import NewSlider from '../hoc/new-slider';
import RingCard from '../molecules/ring-card';
import Heading, { IHeadingProps } from '../atoms/heading';

interface IRingSliderProps {
    className?: string;
    headingProps?: Omit<IHeadingProps, 'className'>;
    rings: IRing[];
}

const RingSlider: React.FC<IRingSliderProps> = ({ className = '', rings, headingProps }) => {
    const CardTitleTag = getLessImportantHeading(headingProps?.HeadingTag);
    return (
        <div className={`${className} ${container}`}>
            <Heading className={heading} {...headingProps} />
            <NewSlider settings={newSliderSettings} className={slider} slidesCount={rings.length}>
                {rings.map((ring) => {
                    return (
                        <div key={`content-slide-${ring.articleId}`} className={slide}>
                            <RingCard className={card} ring={ring} TitleTag={CardTitleTag} />
                        </div>
                    );
                })}
            </NewSlider>
        </div>
    );
};

const newSliderSettings = {
    navigation: false,
    pagination: false,
    speed: 500,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
        600: {
            slidesPerView: 2,
        },
        800: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 4,
        },
        1300: {
            slidesPerView: 5,
        },
    },
};

export default RingSlider;
