import React from 'react';
import { Link } from 'gatsby';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    titleBox,
    subtitleText,
    link,
    imageBox,
    ratio,
    radiusBox,
    bottomBox,
    icon,
} from './ring-card.module.scss';
import ChevronIcon from '../../assets/images/svg/cheveron-02.svg';
import { config } from '../../config';
import { IRing } from '../../models/ring.model';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';
import { getIsInternalLink } from '../../utils/get-is-internal-link';
import { getImage } from 'gatsby-plugin-image';

import RatioImage from '../atoms/ratio-image';

const { translationKeys, relations } = config;

const BASE_URL = process.env.BASE_URL || '';
const DEFAULT_COLOR = '#AE7FCF';

interface IContentCardProps {
    className?: string;
    ring: IRing;
    TitleTag?: React.ElementType;
}

const RingCard: React.FC<IContentCardProps> = ({ className = '', ring, TitleTag = 'h2' }) => {
    const { t } = useI18next();
    const { media, color = DEFAULT_COLOR } = ring;
    const { title, lead, url }: Record<string, string> = useTranslation(ring, translationKeys.ring);
    const { remoteImage, alt, url: imageUrl } =
        getMediaWithRelation(media, relations.mainImage) || {};

    const isInternalLink = getIsInternalLink(url, BASE_URL);
    const Tag = getTag(isInternalLink, url);
    const tagProps = getTagProps(isInternalLink, url);

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tag className={`${container} ${className}`} {...tagProps}>
            <div className={radiusBox} style={{ borderColor: color }}>
                <RatioImage
                    className={imageBox}
                    ratioClass={ratio}
                    image={remoteImage && getImage(remoteImage)}
                    alt={alt}
                    fallbackUrl={imageUrl}
                />
            </div>
            <div className={bottomBox}>
                <TitleTag className={titleBox}>
                    <span>{title}</span>
                    <span className={subtitleText}>{lead}</span>
                </TitleTag>
                {url && (
                    <p className={link}>
                        {t('content.card.go')} <ChevronIcon className={icon} />
                    </p>
                )}
            </div>
        </Tag>
    );
};

function getTag(isInternalLink: boolean, url: string | undefined) {
    if (!url) return 'div';
    if (isInternalLink) return Link;
    return 'a';
}

function getTagProps(isInternalLink: boolean, url: string | undefined) {
    if (!url) return;
    if (isInternalLink) {
        return { to: url };
    }
    return {
        href: url,
        target: '_blank',
        rel: 'noopener noreferrer',
    };
}

export default RingCard;
