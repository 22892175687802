// extracted by mini-css-extract-plugin
export var button = "video-player-module--button--VyWwA";
export var container = "video-player-module--container--DwW-q";
export var coverLayer = "video-player-module--cover-layer--Km7tg";
export var fadeIn = "video-player-module--fade-in--d2AYi";
export var icon = "video-player-module--icon--WF51q";
export var iconBox = "video-player-module--icon-box--9Sc+O";
export var img = "video-player-module--img--SlIMW";
export var imgBox = "video-player-module--img-box--d4OMu";
export var player = "video-player-module--player--2tQlo";
export var text = "video-player-module--text--lgtaj";