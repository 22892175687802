import React, { CSSProperties } from 'react';

import { container } from './separator.module.scss';

interface ISeparatorProps {
    className?: string;
    color?: CSSProperties['backgroundColor'];
}

const Separator: React.FC<ISeparatorProps> = ({ className = '', color }) => {
    return <div style={{ color }} className={`${container} ${className}`} />;
};

export default Separator;
