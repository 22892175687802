import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    video,
    about,
    story,
    merchandising,
    brands,
    separator,
    socials,
} from './index.module.scss';
import { config } from '../config';
import { IBannersCollection } from '../models/banner.model';
import { IVideo } from '../models/video.model';
import { TQueryAllResult } from '../models/query-all-result.model';
import { IRing } from '../models/ring.model';
import { IBrandSocial } from '../models/brand-social.model';
import { getNodes } from '../utils/get-nodes';
import { getCollectionByPlaceId } from '../utils/get-collection-by-place-id';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import VideoPlayer from '../components/molecules/video-player';
import AboutSection from '../components/organisms/about-section';
import RingSlider from '../components/organisms/ring-slider';
import Separator from '../components/atoms/separator';
import BrandSocials from '../components/organisms/brand-socials';

const { places } = config;

interface IIndexProps {
    readonly data: {
        allBannersCollection: TQueryAllResult<IBannersCollection>;
        homeVideo: IVideo | null;
        allStoryRing: TQueryAllResult<IRing>;
        allMerchandisingRing: TQueryAllResult<IRing>;
        allBrandsRing: TQueryAllResult<IRing>;
        allBrandSocial: TQueryAllResult<IBrandSocial>;
    };
}

const IndexPage: React.FC<IIndexProps> = ({ data }) => {
    const { t } = useI18next();
    const {
        allBannersCollection,
        homeVideo,
        allStoryRing,
        allBrandsRing,
        allMerchandisingRing,
        allBrandSocial,
    } = data;

    const collections = getNodes(allBannersCollection);
    const mainBanners = getCollectionByPlaceId(collections, places.home)?.banners || [];

    const storyRings = getNodes(allStoryRing);
    const merchandisingRings = getNodes(allMerchandisingRing);
    const brandsRings = getNodes(allBrandsRing);
    const brandSocials = getNodes(allBrandSocial);

    return (
        <>
            <SEO title={t('index.title')} />
            <MainLayout
                isLogoMainHeading={true}
                bannerRatio="high"
                bannersData={mainBanners.map((banner) => ({ banner: banner }))}
                isModalGovOnPage={true}
                showScrollButton={true}
            >
                <Separator className={separator} color="#9C86CB" />
                {storyRings.length > 0 && (
                    <>
                        <RingSlider
                            headingProps={{
                                title: t('index.story.title'),
                                subtitle: t('index.story.subtitle'),
                            }}
                            className={story}
                            rings={storyRings}
                        />
                        <Separator className={separator} color="#9C86CB" />
                    </>
                )}
                {brandSocials.length > 0 && (
                    <>
                        <BrandSocials
                            brandSocials={brandSocials}
                            headingProps={{
                                title: t('index.social.title'),
                                subtitle: t('index.social.subtitle'),
                            }}
                            className={socials}
                        />
                        <Separator className={separator} color="#DE9FA9" />
                    </>
                )}
                {merchandisingRings.length > 0 && (
                    <>
                        <RingSlider
                            headingProps={{
                                title: t('index.merchandising.title'),
                                subtitle: t('index.merchandising.subtitle'),
                            }}
                            className={merchandising}
                            rings={merchandisingRings}
                        />
                        <Separator className={separator} color="#DE9FA9" />
                    </>
                )}
                {homeVideo && (
                    <>
                        <div className={video}>
                            <VideoPlayer
                                video={homeVideo}
                                playText={t('button.play')}
                                showCoverOnPause={true}
                            />
                        </div>
                        <Separator className={separator} color="#DA7D63" />
                    </>
                )}
                {brandsRings.length > 0 && (
                    <RingSlider
                        headingProps={{
                            title: t('index.brands.title'),
                            subtitle: t('index.brands.subtitle'),
                        }}
                        className={brands}
                        rings={brandsRings}
                    />
                )}
                <AboutSection className={about} />
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placesIds: [String!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allBannersCollection(filter: { placeId: { in: $placesIds } }) {
            edges {
                node {
                    ...bannersCollectionFields
                }
            }
        }

        allStoryRing: allRing(
            filter: { category: { businessMeaning: { eq: 100 } } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...ringFields
                }
            }
        }

        allMerchandisingRing: allRing(
            filter: { category: { businessMeaning: { eq: 101 } } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...ringFields
                }
            }
        }

        allBrandsRing: allRing(
            filter: { category: { businessMeaning: { eq: 102 } } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...ringFields
                }
            }
        }

        allBrandSocial(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...brandSocialFields
                }
            }
        }

        homeVideo {
            translations {
                en {
                    url
                }
                pl {
                    url
                }
            }
            media {
                ...mediaFields
                remoteImage {
                    childImageSharp {
                        gatsbyImageData(quality: 85, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

export default IndexPage;
