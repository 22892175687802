import React from 'react';

import { container, titleText, subtitleText } from './heading.module.scss';

export interface IHeadingProps {
    className?: string;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    HeadingTag?: React.ElementType;
}

const Heading: React.FC<IHeadingProps> = ({
    className = '',
    title,
    subtitle,
    HeadingTag = 'h2',
}) => {
    if (!title && !subtitle) return null;

    return (
        <HeadingTag className={`${container} ${className}`}>
            {title && <span className={titleText}>{title}</span>}
            {subtitle && <span className={subtitleText}>{subtitle}</span>}
        </HeadingTag>
    );
};

export default Heading;
