import React from 'react';

import { container, heading, list } from './brand-socials.module.scss';
import { IBrandSocial } from '../../models/brand-social.model';

import Heading, { IHeadingProps } from '../atoms/heading';
import BrandSocialCard from '../molecules/brand-social-card';

interface IBrandSocialsProps {
    className?: string;
    brandSocials: IBrandSocial[];
    headingProps?: Omit<IHeadingProps, 'className'>;
}

const BrandSocials: React.FC<IBrandSocialsProps> = ({
    className = '',
    brandSocials,
    headingProps,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <Heading className={heading} {...headingProps} />
            <ul className={list}>
                {brandSocials.map((brandSocial) => {
                    return (
                        <li key={`brand-social-item-${brandSocial.socialId}`}>
                            <BrandSocialCard brandSocial={brandSocial} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BrandSocials;
